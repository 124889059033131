import t from './localization';
import { useLocation } from 'react-router-dom';

export const prefixString = (prefix, value) => {
  return prefix + value.charAt(0).toUpperCase() + value.slice(1);
}

export const exactAlertType = (alert) => {
  if (typeof(alert) != 'undefined') {
    if (alert.type == 'alarm') {
      if (alert.attributes.hasOwnProperty('alarms')) {
        if (alert.attributes.alarms == 'seatbelt,lights,seatbelt+lights') {
          return 'seatbeltAndLights'
        }
        return alert.attributes.alarms
      }
    }
  }  
  return alert.type;
}

export const unprefixString = (prefix, value) => {
  return value.charAt(prefix.length).toLowerCase() + value.slice(prefix.length + 1);
}

export const addEditString = (addString, editString) => {
  const path = useLocation();
  var pathArray = path.pathname.split('/')
  if (isNaN(pathArray[pathArray.length-1])) {
    return addString;
  }
  return editString;
}

export const isAdd = () => {
  const path = useLocation();
  var pathArray = path.pathname.split('/')
  if (isNaN(pathArray[pathArray.length-1])) {
    return true;
  }
  return false;
}

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emails = email.split(',').map(email => email.trim());
  for (const email of emails) {
      if (!emailRegex.test(email)) {
          return false;
      }
  }
  return true;
}

export const generateRandomString = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}